<template>
    <div class="attachment-thumbnail">
        <a class="attachment-thumbnail-preview js-open-viewer attachment-thumbnail-preview-is-cover"
            :href="media.src"
            target="_blank" title="Hofladen-News-Banner.jpg" 
            style="background-color: #9a7a5e;"
            :style="'background-image: url('+media.src+');'"
            rel="noreferrer nofollow noopener">
        </a>
        <p class="attachment-thumbnail-details js-open-viewer">
            <span class="attachment-thumbnail-name">{{ media.name }}.{{ media.mime }}</span>
            
            <span class="u-block quiet attachment-thumbnail-details-title-options">
                <span>Hinzugefügt <span class="date past" :dt="media.created_at" title="February 24, 2022 10:14 AM">{{ media.created_at | formatDateTime }}</span></span>
                <!-- <span>
                    <a class="attachment-thumbnail-details-title-options-item js-reply" href="#">
                        <span class="attachment-thumbnail-details-options-item-text">Comment</span>
                    </a>
                </span> -->
                <span>
                    <a class="attachment-thumbnail-details-title-options-item dark-hover js-confirm-delete" href="#" @click="removeAttachment">
                        <span class="attachment-thumbnail-details-options-item-text">Delete</span>
                    </a>
                </span>
                <!-- <span>
                    <a class="attachment-thumbnail-details-title-options-item dark-hover js-edit" href="#">
                        <span class="attachment-thumbnail-details-options-item-text">Edit</span>
                    </a>
                </span> -->
            </span>


            <span class="quiet attachment-thumbnail-details-options">
                <a class="attachment-thumbnail-details-options-item dark-hover hide js-make-cover" v-if="card.cover_type != 'Spatie\\MediaLibrary\\MediaCollections\\Models\\Media' || card.cover_id != media.id" @click="saveCover('Spatie\\MediaLibrary\\MediaCollections\\Models\\Media', media.id)" href="#">
                    <span class="icon-sm icon-card-cover"></span>
                    <span class="attachment-thumbnail-details-options-item-text">Make cover</span>
                </a>
                <a class="attachment-thumbnail-details-options-item dark-hover js-remove-cover" href="#" v-if="card.cover_type == 'Spatie\\MediaLibrary\\MediaCollections\\Models\\Media' && card.cover_id == media.id" @click="removeCover">
                    <span class="icon-sm icon-card-cover"></span>
                    <span class="attachment-thumbnail-details-options-item-text">Remove cover</span>
                </a>
            </span>
        </p>
    </div>
</template>

<script>
export default {

    props:[
        'media',
        'card'
    ],

    methods:{
        removeCover(){
            this.axios
                .put('/cards/' + this.card.id, {
                    'cover_type': null,
                    'cover_id': null
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.cover_type = response.data.data.cover_type;
                    this.card.cover_id = response.data.data.cover_id;
                    this.card.cover = response.data.data.cover;
                    this.$bvModal.hide("tagModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        saveCover(type, id){
            this.axios
                .put('/cards/' + this.card.id, {
                    'cover_type': type,
                    'cover_id': id
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.cover_type = response.data.data.cover_type;
                    this.card.cover_id = response.data.data.cover_id;
                    this.card.cover = response.data.data.cover;
                    this.$bvModal.hide("tagModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        removeAttachment(){
            this.$swal({
                    title: "Möchtest du den Anhang wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios
                            .delete('/cards/' + this.card.id + '/attachments/' + this.media.id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Gelöscht",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });                
                                this.$emit('attachment_deleted', this.media.id);
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        }
    }

}
</script>

<style>
    .attachment-thumbnail {
        border-radius: 3px;
        margin: 0 0 8px;
        min-height: 80px;
        overflow: hidden;   
        position: relative;
    }

    .attachment-thumbnail-preview {
        background-color: #091e420a;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 3px;
        height: 80px;
        left: 0;
        margin-top: -40px;
        position: absolute;
        text-align: center;
        text-decoration: none;
        top: 50%;
        width: 112px;
        z-index: 1;
    }
    .attachment-thumbnail-details {
        box-sizing: border-box;
        cursor: pointer;
        margin: 0;
        min-height: 80px;
        padding: 8px 8px 8px 128px;
        z-index: 0;
    }

    .attachment-thumbnail-details .attachment-thumbnail-name {
        word-wrap: break-word;
        font-weight: 700;
    }

    .attachment-thumbnail-details-title-options {
    margin-bottom: 8px;
    }
    .quiet, .quiet a {
        color: #5e6c84;
    }
    .u-block {
        display: block;
    }

    .attachment-thumbnail-details-title-options-item {
        font-size: 14px;
        margin-bottom: 8px;
        text-decoration: underline;
    }

    .attachment-thumbnail-details-title-options :not(:first-child):before {
        content: " - ";
    }

    /* .attachment-thumbnail-details-options-item :not(:first-child):before {
        content: " - ";
    } */

    .attachment-thumbnail-details-options-item-text {
        text-decoration: underline;
    }
</style>