<template>
<div style="margin-bottom: 1em;">
    <div class="card card-outline" @dblclick="openCardModal(card.id)">
        <!-- <div class="card-header bg-danger">
        </div> -->
        <template v-if="editMode == true">
            <div class="row">
                <div class="col-md-12">
                    <textarea class="form-control form-control-sm list-item-edit" dir="auto" data-autosize="true" style="overflow:hidden; overflow-wrap: break-word; resize: none; height:90px;" v-model="form.title" ></textarea>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <button class="btn btn-secondary btn-sm mt-2 form-control" @click="editItem">Speichern</button>
                </div>
                <div class="col-md-6">
                    <button class="btn btn-danger btn-sm mt-2 form-control" @click="editItemStop">Abbrechen</button>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="card-header" style="border-bottom: none;" v-if="card.cover_type == 'App\\Models\\Tag'" :class="card.cover.content">
                &nbsp;
            </div>
            <div class="card-header" style="border-bottom: none;" v-if="card.cover_type == 'Spatie\\MediaLibrary\\MediaCollections\\Models\\Media'">
                <img :src="card.cover.src" style="width: 100%" />
            </div>
            <div class="card-header" style="padding-bottom: 0; border-bottom: none;" v-if="card.tags.length > 0">
                <span class="card-label mod-card-front" :class="tag.content" v-for="tag in card.tags" :key="tag.id">
                    <span class="label-text">&nbsp;</span>
                </span>
            </div>
            <div class="card-header" style="color: #000; border-bottom: none; padding-bottom: 0;" >
                
                <h5 class="card-title" >{{ card.title }}</h5>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" @click="enableEditMode"><i class="fas fa-pen"></i></button>
                </div>
            </div>

            <div class="card-footer" style="background-color: transparent; border-top: none; padding-top: 0;">
                <div class="badges">
                    <span class="js-badges">
                        <div class="badge js-due-date-badge mod-due-date is-due-future" title="This card is due later." v-if="card.due_date != null || card.start_date != null">
                            <span class="badge-icon icon-sm icon-clock badge-due-icon"></span>
                            <i class="fas fa-clock mr-1"></i>
                            <!-- <span class="badge-icon icon-sm icon-checkbox-checked badge-due-checked"></span>
                            <span class="badge-icon icon-sm icon-checkbox-unchecked badge-due-unchecked"></span> -->
                            <span class="badge-text js-due-date-text" v-if="card.due_date == null && card.start_date != null">Starts: {{card.start_date | formatDate}}</span>
                            <span class="badge-text js-due-date-text" v-if="card.due_date != null && card.start_date == null">{{card.due_date | formatDateTime}}</span>
                            <span class="badge-text js-due-date-text" v-if="card.due_date != null && card.start_date != null">{{card.start_date | formatDate}} - {{card.due_date | formatDate}}</span>
                        </div>
                    
                        <div class="badge is-icon-only" title="This card has a description." v-if="card.description != null">
                            <i class="fas fa-align-justify"></i>
                        </div>
                        
                        <div class="badge" title="Comments" v-if="card.comments.length > 0">
                            <i class="fas fa-comment mr-1"></i>
                            <span class="badge-text">{{ card.comments.length }}</span>
                        </div>
                    </span>
                </div>
            </div>
        </template>
        
        
        <!-- <div class="card-body" style="color: #000;">
            <p>{{ card.description }}</p>
        </div> -->
    </div>

    <b-modal :id="'cardModal'+card.id" :hide-footer="true" size="lg" content-class="custom-background" header-class="custom-header">
        <template #modal-header="{ close }">
            
            <div class="row" style="width: 100%;">
                <div class="col-md-11">
                    <h4 class="modal-title" v-if="editModeTitle == false">
                        <span @click="enableTitleEdit"><strong>{{ card.title }}</strong></span>
                        <!-- <button v-if="card.title != null && editModeTitle == false" @click="enableTitleEdit" class="btn btn-tool"><i class="fas fa-pen"></i></button> -->
                    </h4>
                    <template v-if="editModeTitle == true">
                        <textarea v-model="titleForm.title" class="card-description mb-1" data-autosize="true" style="height: 50px;"></textarea>
                        <button class="btn btn-primary btn-sm mr-1 mb-2" @click="saveTitle">Speichern</button>
                        <button class="btn btn-light btn-sm mb-2" @click="disableTitleEdit">Abbrechen</button>
                    </template>

                    <h6 class="modal-subtitle">in Liste {{ list.name }}</h6>
                </div>
                <div class="col-md-1 justify-content-end">
                    <button type="button" aria-label="Close" class="close" @click="close()">x</button>
                </div>
            </div>     

            <div class="row" style="width: 100%;">
                <div class="col-md-12 text-center" v-if="card.cover_type == 'Spatie\\MediaLibrary\\MediaCollections\\Models\\Media'">
                    <img :src="card.cover.src" style="max-width: 100%; max-height: 200px;" /> 
                    <div class="window-cover-menu">
                        <a class="btn btn-secondary bg-light-grey" @click="showCoverModal" href="#" style="margin-right:8px; color: #fff;">
                            <span class="icon-sm icon-card-cover"></span> Cover
                        </a>
                    </div>
                </div>
                
                <div class="col-md-12 text-center" v-if="card.cover_type == 'App\\Models\\Tag'" :class="card.cover.content" style="height: 100px;">
                    &nbsp;
                    <div class="window-cover-menu">
                        <a class="btn btn-secondary bg-light-grey" @click="showCoverModal" href="#" style="margin-right:8px; color: #fff;">
                            <span class="icon-sm icon-card-cover"></span> Cover
                        </a>
                    </div>
                </div>
            </div>          
        </template>
        <div class="row">
            <div class="col-md-9">

                <div class="row" v-if="card.tags.length > 0">
                    <div class="col-md-12">
                        <h6 class="mb-3"><strong>Labels</strong></h6>
                        <div class="card-detail-item card-detail-item-labels u-clearfix js-card-detail-labels">
                            <div class="u-clearfix js-card-detail-labels-list js-edit-label">
                                <span v-for="tag in card.tags" :key="tag.id" @click="openLabelModal" class="card-label card-label-green mod-card-detail mod-clickable" :class="tag.content" title="">
                                    <span class="label-text">&nbsp;</span>
                                </span>
                                <a class="card-detail-item-add-button js-details-edit-labels" @click="openLabelModal">
                                    <span class="icon-sm icon-add"><i class="fas fa-plus"></i></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="card.start_date != null || card.due_date != null">
                    <div class="col-md-12">
                        <h6 class="mb-3" v-if="card.start_date != null && card.due_date == null"><strong>Start Date</strong></h6>
                        <h6 class="mb-3" v-if="card.start_date == null && card.due_date != null"><strong>Due Date</strong></h6>
                        <h6 class="mb-3" v-if="card.start_date != null && card.due_date != null"><strong>Date</strong></h6>
                        <div class="card-detail-start-date-badge js-card-detail-start-date-badge is-clickable">
                            <div class="card-detail-badge-start-date-react-container">
                                <div class="js-react-root">
                                    <button @click="showDateModal" class="_37VgK6EhPJ-KgO _3TTqkG5muwOzqZ _1Tu9wiuW4Te8Rx" data-test-id="start-date-badge-with-date-range-picker" type="button">
                                        <span v-if="card.start_date != null && card.due_date == null">{{ card.start_date | formatDate }}</span>
                                        <span v-if="card.start_date == null && card.due_date != null">{{ card.due_date | formatDate }}</span>
                                        <span v-if="card.start_date != null && card.due_date != null">{{ card.start_date | formatDate}} - {{ card.due_date | formatDateTime }}</span>

                                        <span class="nch-icon _3W-26fmi3tFfon j0fswhntKdStWa ENexBs-1uoAbna">
                                            <span class="sc-bdVaJa jKipYA" role="img" aria-label="DownIcon">
                                                <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.2929 16.7071L4.22185 9.63606C3.83132 9.24554 3.83132 8.61237 4.22185 8.22185C4.61237 7.83133 5.24554 7.83133 5.63606 8.22185L12 14.5858L18.364 8.22185C18.7545 7.83132 19.3877 7.83132 19.7782 8.22185C20.1687 8.61237 20.1687 9.24554 19.7782 9.63606L12.7071 16.7071C12.3166 17.0977 11.6834 17.0977 11.2929 16.7071Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-12">
                        <h6 class="mb-3"><strong>Beschreibung</strong><button v-if="card.description != null && editModeDescription == false" @click="enableDescriptionEdit" class="ml-1 btn btn-light btn-sm" style="background-color: #091e420a;">Edit</button></h6>
                        <p v-if="card.description == null && editModeDescription == false" class="u-bottom js-hide-with-desc">
                            <a href="#" @click="enableDescriptionEdit" class="description-fake-text-area hide-on-edit js-edit-desc js-hide-with-draft">Detaillierte Beschreibung hinzufügen…</a>
                        </p>
                        <p v-if="card.description != null && editModeDescription == false" class="text-justify format-img" @click="enableDescriptionEdit" style="cursor: pointer;">
                            <span style="max-width:500px" v-html="card.description"></span>
                        </p>
                        <template v-if="editModeDescription == true">
                            <ckeditor :editor="editor" :config="editorConfig" v-model="descriptionForm.description" class="card-description" v-if="notEmptyObject(form)"></ckeditor>
                            <!-- <textarea v-model="descriptionForm.description" placeholder="Detaillierte Beschreibung hinzufügen…" class="card-description" data-autosize="true" style="overflow: hidden; overflow-wrap: break-word; resize: none; height: 176px;"></textarea> -->
                            <button class="btn btn-primary btn-sm mr-1" @click="saveDescription">Speichern</button>
                            <button class="btn btn-light btn-sm" @click="disableDescriptionEdit">Abbrechen</button>
                        </template>
                    </div>
                </div>

                

                 <div class="row mt-5" v-if="card.media.length > 0 || notEmptyObject(card.media)">
                    <div class="col-md-12">
                        <h6 class="mb-3"><strong>Anhänge</strong></h6>
                        <ListingItemAttachment v-for="media in card.media" :key="media.uuid" :card="card" :media="media" v-on:attachment_deleted="deleteAttachment"/>
                        <button class="btn btn-secondary btn-sm" @click="enableFileUpload">Anhang hinzufügen</button>
                    </div>
                 </div>

                <div class="row mt-5">
                    <div style="width: 100%" class="file-drop-area" v-if="showProcessImage == true">
                        <div class="spinner-grow" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Bilder werden verarbeitet</span>
                    </div>
                    <!-- <file-upload v-model="files" post-action="/">Upload file</file-upload> -->
                    <div class="file-drop-area text-center" v-if="showUploadForm == true">
                        <span class="choose-file-button text-center">Wähle Dateien</span>
                        <!-- <span class="file-message">or drag and drop files here</span> -->
                        <input class="file-input" type="file" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length">
                        <!-- <b-form-file size="sm" class="file-input" v-model="files" /> -->
                    </div>
                    <!-- {{ files }} -->
                </div>

                <div class="row mt-5">
                    <div class="col-md-12">
                        <h6 class="mb-3"><strong>Kommentare</strong></h6>
                        <p v-if="newComment == false" class="u-bottom js-hide-with-desc">
                            <a href="#" @click="enableNewComment" class="description-fake-text-area hide-on-edit js-edit-desc js-hide-with-draft">Kommentar hinzufügen…</a>
                        </p>
                        <template v-if="newComment == true">
                            <Mentionable :keys="['@']" :items="users" offset="6" insert-space>
                                <textarea v-model="commentForm.content" placeholder="Kommentar hinzufügen…" class="card-description" data-autosize="true" style="overflow-x: hidden; overflow-y: scroll; overflow-wrap: break-word; resize: none; height: 88px;"></textarea>
                                <template #item-@="{ item }">
                                    <div class="user">
                                        {{ item.username }}
                                        <span class="dim">
                                            ({{ item.first_name }} {{ item.last_name }})
                                        </span>
                                    </div>
                                </template>
                            </Mentionable>
                            <button class="btn btn-primary btn-sm mr-1" @click="saveComment">Speichern</button>
                            <button class="btn btn-light btn-sm" @click="disableNewComment">Abbrechen</button>
                        </template>

                        <template v-if="card.comments.length > 0">
                            <ListingItemComment v-for="comment in card.comments" :key="comment.id" :card="card" :comment="comment" :users="users" v-on:comment_deleted="deleteComment" />
                        </template>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <h6>Kartenfunktionen</h6>
                <!-- <button class="btn btn-light btn-sm mb-2" style="background-color: #e4f0f6; width: 100%; text-align: left;" ><i class="fas fa-users mr-1"></i> Members</button> -->
                <button class="btn btn-light btn-sm mb-2" style="background-color: #e4f0f6; width: 100%; text-align: left;" @click="openLabelModal"><i class="fas fa-tags mr-1"></i> Labels</button>
                <!-- <button class="btn btn-light btn-sm mb-2" style="background-color: #e4f0f6; width: 100%; text-align: left;" ><i class="fas fa-check-square mr-1"></i> Checklists</button> -->
                <button class="btn btn-light btn-sm mb-2" style="background-color: #e4f0f6; width: 100%; text-align: left;" @click="showDateModal"><i class="fas fa-clock mr-1"></i> Datum</button>
                <button v-if="card.media.length == 0" class="btn btn-light btn-sm mb-2" style="background-color: #e4f0f6; width: 100%; text-align: left;" @click="enableFileUpload"><i class="fas fa-paperclip mr-1"></i> Anhänge</button>
                <button class="btn btn-light btn-sm mb-2" style="background-color: #e4f0f6; width: 100%; text-align: left;"  @click="showCoverModal"><i class="fas fa-server mr-1"></i> Hintergrund</button>
                <!-- <button class="btn btn-light btn-sm mb-2" style="background-color: #e4f0f6; width: 100%; text-align: left;"  @click="cloneCard"><i class="fas fa-trash mr-1"></i> Kopieren</button> -->
                <button class="btn btn-light btn-sm mb-2" style="background-color: #e4f0f6; width: 100%; text-align: left;"  @click="deleteCard()"><i class="fas fa-trash mr-1"></i> Löschen</button>
            </div>
        </div>
        
        <!-- <div class="form-group"> -->
            <!-- <label for="tag" class="control-label">Hintergrund*</label> -->
            <!-- <ul class="new_board_list">
                <li v-for="tag in tags" :key="tag.id" class="new_board_item">
                    <button type="button" :class="tag.content" @click="selectTag(tag.id)">
                        <span class="new_board_item_icon" v-if="tag.id == board.cover.id">
                            <span class="new_board_item_icon_self" role="img" aria-label="CheckIcon">
                                <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.73534 12.3223C6.36105 11.9162 5.72841 11.8904 5.3223 12.2647C4.91619 12.639 4.89039 13.2716 5.26467 13.6777L8.87678 17.597C9.41431 18.1231 10.2145 18.1231 10.7111 17.6264C10.7724 17.5662 10.7724 17.5662 11.0754 17.2683C11.3699 16.9785 11.6981 16.6556 12.0516 16.3075C13.0614 15.313 14.0713 14.3169 15.014 13.3848L15.0543 13.3449C16.7291 11.6887 18.0004 10.4236 18.712 9.70223C19.0998 9.30904 19.0954 8.67589 18.7022 8.28805C18.309 7.90022 17.6759 7.90457 17.2881 8.29777C16.5843 9.01131 15.3169 10.2724 13.648 11.9228L13.6077 11.9626C12.6662 12.8937 11.6572 13.8889 10.6483 14.8825C10.3578 15.1685 10.0845 15.4375 9.83288 15.6851L6.73534 12.3223Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </span>
                    </button>
                </li>
            </ul> -->
        <!-- </div> -->

        <b-modal id="labelModal" content-class="custom-background" size="sm" ok-only ok-title="Speichern" @ok="saveTags">
            <div class="form-group">
                <label for="tag" class="control-label">Tags</label>
                <ul class="new_board_list">
                    <li v-for="tag in tags" :key="tag.id" class="new_board_item">
                        <button type="button" :class="tag.content" @click="selectTag(tag.id)">
                            <span class="new_board_item_icon" v-if="selectedTags.includes(tag.id)">
                                <span class="new_board_item_icon_self" role="img" aria-label="CheckIcon">
                                    <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.73534 12.3223C6.36105 11.9162 5.72841 11.8904 5.3223 12.2647C4.91619 12.639 4.89039 13.2716 5.26467 13.6777L8.87678 17.597C9.41431 18.1231 10.2145 18.1231 10.7111 17.6264C10.7724 17.5662 10.7724 17.5662 11.0754 17.2683C11.3699 16.9785 11.6981 16.6556 12.0516 16.3075C13.0614 15.313 14.0713 14.3169 15.014 13.3848L15.0543 13.3449C16.7291 11.6887 18.0004 10.4236 18.712 9.70223C19.0998 9.30904 19.0954 8.67589 18.7022 8.28805C18.309 7.90022 17.6759 7.90457 17.2881 8.29777C16.5843 9.01131 15.3169 10.2724 13.648 11.9228L13.6077 11.9626C12.6662 12.8937 11.6572 13.8889 10.6483 14.8825C10.3578 15.1685 10.0845 15.4375 9.83288 15.6851L6.73534 12.3223Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
        </b-modal>

        <b-modal id="coverModal" title="Cover wählen" content-class="custom-background" size="sm" ok-only ok-title="Speichern" @ok="saveCover('Spatie\\MediaLibrary\\MediaCollections\\Models\\Media')">
            <div class="form-group">
                <label for="tag" class="control-label">Tags</label>
                <ul class="new_board_list">
                    <li v-for="tag in tags" :key="tag.id" class="new_board_item">
                        <button type="button" :class="tag.content" @click="selectCoverTag(tag.id)">
                            <span class="new_board_item_icon" v-if="selectedCover.type == 'App\\Models\\Tag' && selectedCover.id == tag.id">
                                <span class="new_board_item_icon_self" role="img" aria-label="CheckIcon">
                                    <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.73534 12.3223C6.36105 11.9162 5.72841 11.8904 5.3223 12.2647C4.91619 12.639 4.89039 13.2716 5.26467 13.6777L8.87678 17.597C9.41431 18.1231 10.2145 18.1231 10.7111 17.6264C10.7724 17.5662 10.7724 17.5662 11.0754 17.2683C11.3699 16.9785 11.6981 16.6556 12.0516 16.3075C13.0614 15.313 14.0713 14.3169 15.014 13.3848L15.0543 13.3449C16.7291 11.6887 18.0004 10.4236 18.712 9.70223C19.0998 9.30904 19.0954 8.67589 18.7022 8.28805C18.309 7.90022 17.6759 7.90457 17.2881 8.29777C16.5843 9.01131 15.3169 10.2724 13.648 11.9228L13.6077 11.9626C12.6662 12.8937 11.6572 13.8889 10.6483 14.8825C10.3578 15.1685 10.0845 15.4375 9.83288 15.6851L6.73534 12.3223Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </span>
                        </button>
                    </li>
                </ul>
            </div>

            <div class="form-group" v-if="card.media.length > 0">
                <label for="tag" class="control-label">Media</label>
                <div class="_1ovuKbhlHul4_B">
                    <button @click="selectCoverMedia(media.id)" v-for="media in card.media" :key="media.id" :style="'background-image: url(' + media.src + ');'" class="button dwbBm4635N4vIF" :class="(selectedCover.type == 'Spatie\\MediaLibrary\\MediaCollections\\Models\\Media' && selectedCover.id == media.id) ? 'sJhXSWkgkgCv7c' : ''" style="background-color: rgb(96, 148, 158); background-size: contain;"></button>
                </div>
            </div>

            <div class="form-group">
                <button class="btn btn-danger btn-sm" type="button" @click="removeCover">Kein Hintergrund</button>
            </div>
        </b-modal>

        <b-modal id="dateModal" content-class="custom-background" size="sm" ok-only ok-title="Speichern" @ok="handleDate">
            <div class="form-group">
                <label for="tag" class="control-label">Startdatum</label>
                <input type="date" class="form-control form-control-sm" v-model="selectedDate.start" />
            </div>

            <div class="form-group">
                <label for="tag" class="control-label">Fälligkeitsdatum</label>
                <input type="datetime-local" class="form-control form-control-sm" v-model="selectedDate.due" />
            </div>

            <div class="form-group">
                <button class="btn btn-danger btn-sm" @click="setNoDate">Kein Datum</button>
            </div>
        </b-modal>

    </b-modal>
</div>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';
import moment from 'moment'
import 'floating-vue/dist/style.css'
import { Mentionable } from 'vue-mention'
import ListingItemComment from './ListingItemComment';
import ListingItemAttachment from './ListingItemAttachment';
export default {
    props:[
        'card',
        'list',
        'users',
        'tags'
    ],

    components: {
        Mentionable,
        ListingItemComment,
        ListingItemAttachment
    },

    

    data(){
        return{
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            editMode: false,
            editModeDescription: false,
            editModeTitle: false, 
            newComment: false,
            showUploadForm: false,
            showProcessImage: false,
            files: [],
            form: new window.Form({
                title: ''
            }),
            descriptionForm: new window.Form({
                description: ''
            }),
            titleForm: new window.Form({
                title: ''
            }),
            commentForm: new window.Form({
                content: ''
            }),
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { "My-Awesome-Header": "header value" }
            },
            selectedTags: [],
            selectedCover: {
                'type': null,
                'id': null,
            },
            selectedDate: {
                start: null,
                due: null,
            }
        }
    },

    methods:{

        saveDate(){
            this.axios
                .put('/cards/' + this.card.id, {
                    'start_date': this.selectedDate.start,
                    'due_date': this.selectedDate.due
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.start_date = response.data.data.start_date;
                    this.card.due_date = response.data.data.due_date;
                    this.$bvModal.hide("dateModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        handleDate(bvModalEvt){
            bvModalEvt.preventDefault();
            this.saveDate();
        },

        setNoDate(){
            this.selectedDate.start = null;
            this.selectedDate.due = null;
        },

        showDateModal(){
            if(this.card.start_date != null){
                this.selectedDate.start = moment(this.card.start_date).format('YYYY-MM-DD');
            }
            else{
                this.selectedDate.start = null;
            }

            if(this.card.due_date != null){
                this.selectedDate.due = moment(this.card.due_date).format('YYYY-MM-DDThh:mm');
            }
            else{
                this.selectedDate.due = null;
            }         
            
            this.$bvModal.show("dateModal");
        },

        deleteCard() {
            this.$swal({
                    title: "Möchtest du die Karte wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/cards/" + this.card.id)
                            .then(() => {
                                this.$bvModal.hide('cardModal'+this.card.id);
                                this.$emit('card_deleted', this.card.id);
                                this.$swal({
                                    icon: "success",
                                    title: "Die Karte wurde erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        // cloneCard()
        // {
        //     this.$swal({
        //         title: "Möchtest du die Karte wirklich duplizieren?",
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonColor: "#3085d6",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: `Ja, duplizieren!!!`,
        //         denyButtonText: `Abbrechen`,
        //     })
        //     .then((result) => {
        //         /* Read more about isConfirmed, isDenied below */
        //         if (result.isConfirmed) {
        //             //Send request to the Server
        //             this.axios
        //                 .post("/cards/", {
        //                     'card_id': this.card.id,
        //                 })
        //                 .then((res) => {
        //                     this.$swal({
        //                         icon: "success",
        //                         title: "Karte wurde dupliziert, bitte Seite neu laden.",
        //                         toast: true,
        //                         position: 'top-end',
        //                         showConfirmButton: false,
        //                         timer: 2000,
        //                         timerProgressBar: true,
        //                     });
        //                 })
        //                 .catch((error) => {
        //                     this.$swal({
        //                         icon: "error",
        //                         title: "Oops...",
        //                         text: error.response.data.error,
        //                     });
        //                 });
        //         }
        //     });
        // },

        removeCover(){
            this.axios
                .put('/cards/' + this.card.id, {
                    'cover_type': null,
                    'cover_id': null
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.cover_type = response.data.data.cover_type;
                    this.card.cover_id = response.data.data.cover_id;
                    this.card.cover = response.data.data.cover;
                    this.$bvModal.hide("coverModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        handleCover(bvModalEvt){
            bvModalEvt.preventDefault();
            this.saveCover();
        },

        selectCoverTag(id){
            this.selectedCover.id = id;
            this.selectedCover.type = 'App\\Models\\Tag';
        },

        selectCoverMedia(id){
            this.selectedCover.id = id;
            this.selectedCover.type = 'Spatie\\MediaLibrary\\MediaCollections\\Models\\Media';
        },

        showCoverModal(){
            this.selectedCover.type = this.card.cover_type;
            this.selectedCover.id = this.card.cover_id;
            this.$bvModal.show("coverModal");
        },
        
        saveCover(){
            this.axios
                .put('/cards/' + this.card.id, {
                    'cover_type': this.selectedCover.type,
                    'cover_id': this.selectedCover.id
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.cover_type = response.data.data.cover_type;
                    this.card.cover_id = response.data.data.cover_id;
                    this.card.cover = response.data.data.cover;
                    this.$bvModal.hide("coverModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        handleTagOk(bvModalEvt){
            bvModalEvt.preventDefault();
            this.saveTags();
        },

        saveTags(){
            this.axios
                .put('/cards/' + this.card.id, {
                    'tags': this.selectedTags,
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.tags = response.data.data.tags;
                    this.$bvModal.hide("tagModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        selectTag(id){
            const label = this.selectedTags.findIndex(item => item === id);
            if(label == -1){
                //Label wurde noch net gefunden. Füge also hinzu
                this.selectedTags.push(id);
            }
            else{
                this.$delete(this.selectedTags, label)
            }
        },

        openLabelModal(){
            if(this.card.tags.length > 0){
                this.selectedTags = [];
                this.card.tags.forEach(function(item){
                    this.selectedTags.push(item.id)
                },this)
            }
            this.$bvModal.show("labelModal");
        },

        enableFileUpload(){
            this.showUploadForm = true;
        },

        disableFileUpload(){
            this.showUploadForm = false;
        },

        filesChange(fieldName, fileList){
            // var files = e.target.files || e.dataTransfer.files;
            if (!fileList.length){
                return;
            }
            this.showUploadForm = false;
            this.showProcessImage = true;
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            const formData = new FormData();
            // Array
            // .from(Array(fileList.length).keys())
            // .map(x => {
            //     formData.append(fieldName, fileList[x], fileList[x].name);
            // });
            formData.append('file', fileList[0])

            // console.log(formData);
            this.axios
                .post('/cards/' + this.card.id + '/attachments', formData, config)
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.media.unshift(response.data.data);
                    this.showProcessImage = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        deleteAttachment(id){
            // console.log('Kommentar gelöscht mit ID: ' + id);
            const idx = this.card.media.findIndex(item => item.id === id);
            this.$delete(this.card.media, idx);
        },

        deleteComment(id){
            // console.log('Kommentar gelöscht mit ID: ' + id);
            const idx = this.card.comments.findIndex(item => item.id === id);
            this.$delete(this.card.comments, idx);
        },

        saveComment(){
            this.commentForm
                .post('/cards/' + this.card.id + '/comments')
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.comments.unshift(response.data.data);
                    this.commentForm.clear();
                    this.commentForm.reset();
                    this.newComment = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        enableNewComment(){
            this.newComment = true;
        },

        disableNewComment(){
            this.newComment = false;
            this.descriptionForm.content = '';
        },

        saveDescription(){
            // console.log(this.$echo);
            this.descriptionForm
                .put('/cards/' + this.card.id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.description = this.descriptionForm.description;
                    this.descriptionForm.clear();
                    this.descriptionForm.reset();
                    this.editModeDescription = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        enableDescriptionEdit(){
            this.editModeDescription = true;
            this.descriptionForm.description = this.card.description;
        },

        disableDescriptionEdit(){
            this.editModeDescription = false;
            this.descriptionForm.description = '';
        },

        openCardModal(id){
            this.$bvModal.show("cardModal"+id);
        },

        editItem(){
            this.form
                .put('/cards/' + this.card.id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.title = this.form.title;
                    this.form.clear();
                    this.form.reset();
                    this.editMode = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        editItemStop(){
            this.editMode = false;
            this.form.clear();
            this.form.reset();
        },

        enableEditMode(){
            this.editMode = true;
            this.form.title = this.card.title;
        }, 

        saveTitle(){
            this.titleForm
                .put('/cards/' + this.card.id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.card.title = this.titleForm.title;
                    this.titleForm.clear();
                    this.titleForm.reset();
                    this.editModeTitle = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        enableTitleEdit(){
            this.editModeTitle = true;
            this.titleForm.title = this.card.title;
        },

        disableTitleEdit(){
            this.editModeTitle = false;
            this.titleForm.title = '';
        },
    }
}
</script>

<style scoped>

    .drag > div{
        transform: rotate(5deg);
    }
    
    .ghost {
        background: lightgray;
        border-radius: 6px;
    }
    .ghost > div {
        visibility: hidden;
    }

    .list-item-edit{
        -webkit-appearance: none;
        background-color: #fafbfc;
        border: none;
        border-radius: 3px;
        box-shadow: inset 0 0 0 2px #dfe1e6;
        box-sizing: border-box;
        display: block;
        line-height: 20px;
        margin-bottom: 12px;
        outline: none;
        padding: 8px 12px;
        transition-duration: 85ms;
        transition-property: background-color,border-color,box-shadow;
        transition-timing-function: ease;
        width:100%;
        /* padding: 0; */
        /* background-color: initial; */
        box-shadow: none;
    }

    .u-bottom {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .description-fake-text-area {
        background-color: #091e420a;
        border: none;
        border-radius: 3px;
        box-shadow: none;
        display: block;
        min-height: 40px;
        padding: 8px 12px;
        text-decoration: none;
    }

    a {
        color: #172b4d;
    }

    .card-description {
        -webkit-appearance: none;
        background-color: #fff;
        border: none;
        border-radius: 3px;
        box-shadow: inset 0 0 0 2px #dfe1e6;
        box-sizing: border-box;
        display: block;
        line-height: 20px;
        margin-bottom: 12px;
        outline: none;
        padding: 8px 12px;
        transition-duration: 85ms;
        transition-property: background-color,border-color,box-shadow;
        transition-timing-function: ease;
        width: 100%;
    }

    ::v-deep .custom-background{
        background-color: #f4f5f7 !important; 
    }

    ::v-deep .custom-header{
        display: block; 
    }

    .file-uploads {
    overflow: hidden;
    position: relative;
    text-align: center;
    display: inline-block;
    }
    .file-uploads.file-uploads-html4 input[type="file"] {
    opacity: 0;
    font-size: 20em;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    }
    .file-uploads.file-uploads-html5 input[type="file"] {
    overflow: hidden;
    position: fixed;
    width: 1px;
    height: 1px;
    z-index: -1;
    opacity: 0;
    }

    .file-drop-area {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        padding: 25px;
        border: 1px dashed rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        transition: 0.2s;
        }

        .choose-file-button {
        flex-shrink: 0;
        background-color: rgba(255, 255, 255, 0.04);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        padding: 8px 15px;
        margin-right: 10px;
        font-size: 12px;
        text-transform: uppercase;
        }

        .file-message {
        font-size: small;
        font-weight: 300;
        line-height: 1.4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        }

        .file-input {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
        
        }

        .mt-100{
            margin-top:100px;
        }

        .card-label.mod-card-detail {
    border-radius: 3px;
    box-sizing: border-box;
    display: block;
    float: left;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
    margin: 0 4px 4px 0;
    min-width: 40px;
    padding: 0 12px;
    width: auto;
}

.card-label.mod-clickable {
    cursor: pointer;
}

.card-detail-item-add-button {
    background-color: #091e420a;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    cursor: pointer;
    display: block;
    float: left;
    margin: 0 8px 8px 0;
    transition-duration: 85ms;
    transition-property: background-color,border-color,box-shadow;
    transition-timing-function: ease;
}

.card-detail-item-add-button .icon-sm {
    font-size: 16px;
    height: 32px;
    line-height: 32px;
    padding: 8px;
    width: 16px;
}

.new_board_list{
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* padding-inline-start: 40px; */
}

.new_board_item{
    width: 40px;
    height: 32px;
    display: list-item;
    text-align: -webkit-match-parent;
    color: #172b4d;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border: none;
}

.new_board_item button {
    align-items: center;
    background-position: center center;
    background-size: cover;
    border-radius: 3px;
    box-shadow: none;
    color: rgba(255, 255, 255, 0.4);
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    min-height: 0;
    outline: 0;
    padding: 0;
    position: relative;
    line-height: 0;
    width: 100%;
    cursor: pointer;
    border: none;
}

.new_board_item_icon{
    position: relative;
    z-index: 1;
    flex-basis: 100%;
    font-size: 16px;
    /* height: 24px; */
    /* line-height: 24px; */
    display: inline-block;
}

.new_board_item_icon_self{
    color: rgb(255, 255, 255);
    display: inline-block;
    fill: inherit;
    flex-shrink: 0;
    line-height: 1;
}

.card-label {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #b3bac5;
    border-radius: 4px;
    color: #fff;
    display: block;
    margin-right: 4px;
    max-width: 100%;
    overflow: hidden;
    padding: 4px 6px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-label.mod-card-front {
    float: left;
    font-size: 12px;
    font-weight: 700;
    height: 8px;
    line-height: 100px;
    margin: 0 4px 4px 0;
    max-width: 40px;
    min-width: 40px;
    padding: 0;
    text-shadow: none;
    width: auto;
}

.window-cover-menu {
    align-items: center;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    position: absolute;
    width: 100%;
}

.window-cover-menu-button {
    background-color: #00000014;
    border-radius: 3px;
    color: #172b4d;
    padding: 6px 12px 6px 6px;
    text-decoration: none;
}

._1ovuKbhlHul4_B {
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(0, 0, 0);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 4px;
    margin: 0 -4px;
    overflow-x: hidden;
    grid-template-columns: 1fr 1fr 1fr;
}

._1ovuKbhlHul4_B .dwbBm4635N4vIF {
    margin: 0;
    border-radius: 3px;
    height: 72px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: none;
    height: 48px;
    width: 88px;
    border: none;
}

._1ovuKbhlHul4_B .dwbBm4635N4vIF.sJhXSWkgkgCv7c {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #0079bf;
    filter: none;
}

card-detail-due-date-badge, .card-detail-list-badge, .card-detail-start-date-badge {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0 8px 8px 0;
}

._37VgK6EhPJ-KgO {
    margin-bottom: 0;
}

._3TTqkG5muwOzqZ {
    color: #172b4d;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
    padding: 6px 12px;
    text-decoration: none;
    white-space: normal;
    background-color: rgba(9, 30, 66, 0.04);
    box-shadow: none;
    border: none;
    transition-property: background-color, border-color, box-shadow;
    transition-duration: 85ms;
    transition-timing-function: ease;
}

.card-detail-item-header {
    color: #5e6c84;
    display: block;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    line-height: 20px;
    margin: 0 8px 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.badge {
    color: #5e6c84;
    display: inline-block;
    margin: 0 4px 0 0;
    max-width: 100%;
    min-height: 20px;
    overflow: hidden;
    /* padding: 2px; */
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    vertical-align: top;
}

.badges {
    float: left;
    margin-left: -2px;
    max-width: 100%;
}

::v-deep img {
  max-width: 100vh !important;
}

</style>